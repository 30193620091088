<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header
                    title="Component Registration"
                >
                  <template v-slot:description>
                    Register all aviary components globally in the "main.js" vue file or import them individually in the files that you will be using them in.
                  </template>
                </hb-page-header>
            </hb-header>
        </div>

       <hb-card title="Global Registration" class="mt-4 mb-6">
          <div class="mt-4 mb-6 mx-6">
              Place the code below in the "main.js" vue file to globally import and register all aviary components. If you use this method, there is no need to import/register individual components in files. All aviary components will be available for use on any page without additional importing/registering.
          </div>

          <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
import { HbAutocomplete, HbBladeHeader, HbBottomActionBar, HbBreadcrumb, HbBtn, HbCard, HbCheckbox, HbChip, HbCombobox, HbCommunication, HbDataTable, HbDataTableHeader, HbEmptyState, HbExpansionPanel, HbFileInput, HbForm, HbGlobalNotification, HbHeader, HbIcon, HbInfoCard, HbLink, HbMediaViewer, HbMenu, HbMiniProfile, HbMiniProfileItem, HbModal, HbNotification, HbPageHeader, HbPageMenu, HbPropertySelector, HbRadio, HbRadioGroup, HbSelect, HbSpaceIcon, HbStatus, HbStatusGeneral, HbStepper, HbStepperHeader, HbStepperStep, HbSwitch, HbTabs, HbTextarea, HbTextField, HbTimeline, HbTimelineItem, HbTimelineItemAction, HbTimelineItemSubAction, HbToast, HbToastItem, HbTooltip } from 'hummingbird-aviary';

Vue.component('HbAutocomplete', HbAutocomplete);
Vue.component('HbBladeHeader', HbBladeHeader);
Vue.component('HbBottomActionBar', HbBottomActionBar);
Vue.component('HbBreadcrumb', HbBreadcrumb);
Vue.component('HbBtn', HbBtn);
Vue.component('HbCard', HbCard);
Vue.component('HbCheckbox', HbCheckbox);
Vue.component('HbChip', HbChip);
Vue.component('HbCombobox', HbCombobox);
Vue.component('HbCommunication', HbCommunication);
Vue.component('HbDataTable', HbDataTable);
Vue.component('HbDataTableHeader', HbDataTableHeader);
Vue.component('HbEmptyState', HbEmptyState);
Vue.component('HbExpansionPanel', HbExpansionPanel);
Vue.component('HbFileInput', HbFileInput);
Vue.component('HbForm', HbForm);
Vue.component('HbGlobalNotification', HbGlobalNotification);
Vue.component('HbHeader', HbHeader);
Vue.component('HbIcon', HbIcon);
Vue.component('HbInfoCard', HbInfoCard);
Vue.component('HbLink', HbLink);
Vue.component('HbMediaViewer', HbMediaViewer);
Vue.component('HbMenu', HbMenu);
Vue.component('HbMiniProfile', HbMiniProfile);
Vue.component('HbMiniProfileItem', HbMiniProfileItem);
Vue.component('HbModal', HbModal);
Vue.component('HbNotification', HbNotification);
Vue.component('HbPageHeader', HbPageHeader);
Vue.component('HbPageMenu', HbPageMenu);
Vue.component('HbPropertySelector', HbPropertySelector);
Vue.component('HbRadio', HbRadio);
Vue.component('HbRadioGroup', HbRadioGroup);
Vue.component('HbSelect', HbSelect);
Vue.component('HbSpaceIcon', HbSpaceIcon);
Vue.component('HbStatus', HbStatus);
Vue.component('HbStatusGeneral', HbStatusGeneral);
Vue.component('HbStepper', HbStepper);
Vue.component('HbStepperHeader', HbStepperHeader);
Vue.component('HbStepperStep', HbStepperStep);
Vue.component('HbSwitch', HbSwitch);
Vue.component('HbTabs', HbTabs);
Vue.component('HbTextarea', HbTextarea);
Vue.component('HbTextField', HbTextField);
Vue.component('HbTimeline', HbTimeline);
Vue.component('HbTimelineItem', HbTimelineItem);
Vue.component('HbTimelineItemAction', HbTimelineItemAction);
Vue.component('HbTimelineItemSubAction', HbTimelineItemSubAction);
Vue.component('HbToast', HbToast);
Vue.component('HbToastItem', HbToastItem);
Vue.component('HbTooltip', HbTooltip);
</pre>
          </hb-card>
        </hb-card>

        <hb-card title="Manual Registration" class="mt-4 mb-6">
          <div class="mt-4 mb-6 mx-6">
            To manually import an aviary component in a vue file, follow the example below:
          </div>

          <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;script type="text/babel">

import { HbIcon, HbBtn, HbLink } from 'hummingbird-aviary';

export default {
  name: "AmazingApplicationPage",
  data: function() {
    return {
      amazingProp: false, 
    }
  },
  components: {
      HbIcon,
      HbBtn,
      HbLink
  },
  mounted(){
    console.log('Page Mounted');
  },
  created(){
    console.log('Page Created');
  },
  destroyed(){
    console.log('Page Destroyed');
  },
  filters: {
    formatSomething(value){
      var result = '';

      // add filter logic
      
      return result;
    }
  },
  computed: {
    amazingComputedProp(){
      return this.propFromParent;
    }
  },
  methods: {
    amazingMethod(){
      console.log('Hello World');
    }
  },
  props: {
    propFromParent: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    amazingProp(){
      if(this.amazingProp){
        this.amazingMethod();
      }
    },
  }
}
&lt;/script>
</pre>
          </hb-card>
        </hb-card>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemComponentRegistration",
        data: function() {
            return {
            };
        },
    }
</script>

<style scoped>

</style>
